<template>
  <div>
    <v-container fluid>
      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <ProductForm @close="dialog=false" ref="productForm"/>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="rounded-lg shadow" :loading="overlay" :disabled="overlay">
        <v-row no-gutters>
          <v-col cols="3" class="b-r">
            <Navigation/>
          </v-col>
          <v-col cols="9">
            <v-card-title>
              <div>
                <v-text-field v-model="search" dense filled rounded
                              clearable
                              append-icon="mdi-magnify"
                              placeholder="Recherche..."
                              class="w-300"
                              single-line hide-details></v-text-field>
              </div>
              <v-spacer/>
              <v-btn text class="text-none" disabled>
                {{ products.length }} Élément(s)
              </v-btn>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="getProducts">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="getProducts">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>
              <v-btn color="blue" dark class="ml-1" fab small depressed
                     @click="addItem">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-data-table v-else :headers="headers" :items="products" :search="search">
              <template v-slot:item.actions="{ item }">
                <v-menu offset-y transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" color="primary">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editItem(item)">
                      <v-icon left small>mdi-pencil-outline</v-icon>
                      <v-list-item-title>
                        Modifier
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item.id)">
                      <v-icon left small>mdi-delete-outline</v-icon>
                      <v-list-item-title>
                        Supprimer
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:item.photo="{ item }">

                <v-img v-if="item.photo" :src="FILE_URL + item.photo"
                       class="rounded-circle"
                       width="40" height="40"/>

                <v-img v-else :src="require('@/assets/no-image.png')"
                       class="rounded-circle img-border"
                       width="40" height="40"/>

              </template>
              <template v-slot:item.is_active="{ item }">
                <v-switch v-model="item.is_active" readonly
                          hide-details class="mt-0 pt-0" color="blue"
                          @click="changeStatus(item.id,item.is_active)"></v-switch>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Navigation from "../Navigation";
import {HTTP} from "@/http-common";
import ProductForm from "./ProductForm";

export default {
  components: {ProductForm, Navigation},
  data() {
    return {
      search: '',
      headers: [
        {text: 'Active / Inactive', value: 'is_active'},
        {text: 'Code', value: 'code'},
        {text: 'Nom', value: 'name'},
        {text: 'Catégorie', value: 'category.name'},
        {text: 'Marque', value: 'brand.name'},
        {text: 'Photo', value: 'photo'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      overlay: false,
      dialog: false,
      dialogTitle: '',
      id: '',
      products: [],
      loading: false,
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    addItem() {
      this.dialogTitle = 'Ajouter un produit'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.productForm.add()
      }, 100)
    },
    editItem(item) {
      this.dialogTitle = 'Modifier un produit'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.productForm.edit(item)
      }, 100)
    },
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getProducts() {
      this.products = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('/products').then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.products = res.data.data
        this.refreshStore()
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    refreshStore() {
      this.$store.dispatch('products', this.products)
    },
    changeStatus(id, is_active) {
      this.$Progress.start()
      this.overlay = true
      let data = {
        id: id,
        is_active: !is_active,
      }
      HTTP.post('/products/change-status', data).then(() => {
        const i = this.products.findIndex(item => item.id === id)
        this.products[i].is_active = !is_active
        this.overlay = false
        this.$Progress.finish()
        this.$successMessage = 'Status changé avec succes !'
        this.refreshStore()
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.overlay = false
      })
    },
  },
  created() {
    this.getProducts()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        HTTP.delete('/products/delete/' + this.id).then(() => {
          let i = this.products.findIndex(item => item.id === this.id)
          this.$delete(this.products, i)
          this.$successMessage = 'Cet produit a été supprimée avec succès'
          this.refreshStore()
          this.overlay = false
        }).catch(err => {
          this.overlay = false
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>