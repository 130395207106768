<template>
  <div>
    <form @submit.prevent="save" enctype="multipart/form-data">
      <v-row>
        <v-col>
          <v-text-field label="Code *"
                        hide-details="auto"
                        @input="errors && errors.code ? errors.code = '' : false"
                        :error-messages="errors.code" v-model="form.code"/>
        </v-col>
        <v-col>
          <v-text-field label="Nom *"
                        hide-details="auto"
                        @input="errors && errors.name ? errors.name = '' : false"
                        :error-messages="errors.name" v-model="form.name"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete label="Catégorie *"
                          @input="errors && errors.category_id ? errors.category_id = '' : false"
                          :error-messages="errors.category_id"
                          :items="categories"
                          item-text="name" item-value="id"
                          v-model="form.category_id"
          />
        </v-col>
        <v-col>
          <v-autocomplete label="Marque *"
                          :items="brands" item-text="name" item-value="id"
                          @change="errors && errors.brand_id ? errors.brand_id = '' : false"
                          :error-messages="errors.brand_id" v-model="form.brand_id"/>
        </v-col>
      </v-row>
      <v-file-input label="Photo *"
                    ref="photo"
                    accept="image/png,image/jpeg"
                    small-chips
                    append-icon="mdi-image-outline"
                    @change="uploadFile"
                    show-size
                    @click="errors && errors.photo ? errors.photo = '' : false"
                    :error-messages="errors.photo"/>
      <v-textarea label="Description"
                  @input="errors && errors.description ? errors.description = '' : false"
                  :error-messages="errors.description" v-model="form.description" rows="2"/>
      <div class="d-flex justify-space-between mt-3">
        <v-spacer/>
        <v-btn color="primary" :loading="btnLoading" depressed class="rounded-lg"
               type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,
      form: {
        id: '',
        code: '',
        name: '',
        category_id: '',
        brand_id: '',
        photo: '',
        description: '',
      },
      errors: {},
    }
  },
  methods: {
    uploadFile(file) {
      this.form.photo = file
    },
    edit(item) {
      this.form.id = item.id
      this.form.code = item.code || ''
      this.form.name = item.name || ''
      this.form.category_id = item.category_id
      this.form.brand_id = item.brand_id
      this.form.description = item.description || ''
      this.form.photo = ''
      this.$refs.photo.reset()
      this.errors = {}
    },
    add() {
      this.form.id = ''
      this.form.code = ''
      this.form.name = ''
      this.form.category_id = ''
      this.form.brand_id = ''
      this.form.description = ''
      this.form.photo = ''
      this.$refs.photo.reset()
      this.errors = {}
    },
    save() {
      this.btnLoading = true
      this.$Progress.start()
      const url = this.form.id ? '/products/update' : '/products/store'
      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key])
      })
      HTTP.post(url, formData).then((res) => {
        this.$successMessage = 'Cet produit a été enregistré avec succès'
        if (this.form.id) {
          this.$emit('close')
        }
        if (!this.form.id) {
          this.products.unshift(res.data.data)
        } else {
          const i = this.products.findIndex(item => item.id === this.form.id)
          this.products.splice(i, 1, res.data.data)
        }
        this.btnLoading = false
        this.$Progress.finish()
        this.dialog = !this.productId
        this.add()
        this.refreshStore()
      }).catch(err => {
        this.btnLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
    refreshStore() {
      this.$store.dispatch('products', this.products)
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories
    },
    brands() {
      return this.$store.state.brands
    },
    products() {
      return this.$store.state.products
    },
  }
}
</script>

<style scoped>

</style>